export const LOGIN = "LOGIN";
export const GET_BLOOD_BANK = "GET_BLOOD_BANK";
export const ADD_BLOOD_BANK = "ADD_BLOOD_BANK";
export const UPDATE_BLOOD_BANK = "UPDATE_BLOOD_BANK";
export const DELETE_BLOOD_BANK = "DELETE_BLOOD_BANK";
export const GET_AMBULANCE = "GET_AMBULANCE";
export const ADD_AMBULANCE = "ADD_AMBULANCE";
export const UPDATE_AMBULANCE = "UPDATE_AMBULANCE";
export const DELETE_AMBULANCE = "DELETE_AMBULANCE";
export const ALL_MEDIA_FILE = "ALL_MEDIA_FILE";
export const ADD_MEDIA_FILE = "ADD_MEDIA_FILE";
export const ADD_CONTENT = "ADD_CONTENT";
export const GET_CONTENT = "GET_CONTENT";
