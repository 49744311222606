import {
    ADD_BLOOD_BANK,
    GET_BLOOD_BANK,
    UPDATE_BLOOD_BANK,
    DELETE_BLOOD_BANK,
    LOGIN,
    GET_AMBULANCE,
    UPDATE_AMBULANCE,
    DELETE_AMBULANCE,
    ADD_AMBULANCE,
    ADD_MEDIA_FILE,
    ALL_MEDIA_FILE,
    GET_CONTENT

} from "../actions/actionType";

const AppState = {
};

// const udpateData = (data, key, state = AppState) => {


// }

const deleteData = (data, key, state) => {

    if (data.meta.status === 200) {
        let result = state[key].filter(res => res.service_id !== data.service_id);
        state[key] = [...result];
        return { ...state };
    } else {
        return { ...state };
    }
}


export const appReducer = (state = AppState, action) => {
    switch (action.type) {
        case LOGIN:
            state.loginDetail = action.payload;
            return { ...state };


        case GET_BLOOD_BANK:
            state.bloodbankList = action.payload.output;
            return { ...state };

        case ADD_BLOOD_BANK:
            state.bloodbankList = [...state.bloodbankList, action.payload.output];
            return { ...state };

        case UPDATE_BLOOD_BANK:
            state.bloodbankList = state.bloodbankList.map(bloodbankData => {
                return (bloodbankData.service_id === action.payload.output.service_id) ? action.payload.output : bloodbankData
            });
            return { ...state };

        case DELETE_BLOOD_BANK:
            let bdatas = state.bloodbankList.filter(res => res.service_id !== action.payload.output.service_id);
            state.bloodbankList = [...bdatas];
            return { ...state };

        case GET_AMBULANCE:
            state.ambulanceList = action.payload.output;
            return { ...state };

        case ADD_AMBULANCE:
            state.ambulanceList = [...state.ambulanceList, action.payload.output];
            return { ...state };

        case UPDATE_AMBULANCE:
            state.ambulanceList = state.ambulanceList.map(ambulanceData => {
                return (ambulanceData.service_id === action.payload.output.service_id) ? action.payload.output : ambulanceData
            });
            return { ...state }

        case DELETE_AMBULANCE:
            let adatas = state.ambulanceList.filter(res => res.service_id !== action.payload.output.service_id);
            state.ambulanceList = [...adatas];
            return { ...state }
        case ALL_MEDIA_FILE:
            state.allMedia = action.payload;
            return { ...state };
        case ADD_MEDIA_FILE:
            state.allMedia = action.payload;
        case GET_CONTENT:
            state.content = action.payload;
            return { ...state };
        default:
            return state
    }
}

